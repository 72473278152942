import styled from "@emotion/styled";
import { AiFillFormatPainter } from "react-icons/ai";
import { BiCameraMovie, BiMapAlt, BiWindowOpen } from "react-icons/bi";
import { BsFillHouseFill } from "react-icons/bs";
import {
  GiHammerNails,
  GiMeditation,
  GiTalk,
  GiThreeFriends,
} from "react-icons/gi";
import { IoMdConstruct } from "react-icons/io";
import Inner from "../layout/Inner";
import Section, { SectionTitle, SectionSubTitle } from "../layout/Section";

const BusinessGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 33% 33% auto;

  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
`;

const BusinessItem = styled.div`
  word-break: keep-all;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  line-height: 1.8;

  > div {
    color: ${({ theme }) => theme.colors.font.head};
    display: flex;
    align-items: center;

    > a {
      margin-left: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      color: ${({ theme }) => theme.colors.main};
    }

    > svg {
      color: ${({ theme }) => theme.colors.main};
    }
    font-size: 1.125rem;
    font-weight: bold;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
  }
`;

const About: React.FC = () => {
  return (
    <Section color="sub" direction="column">
      <SectionTitle>ABOUT</SectionTitle>
      <SectionSubTitle>진행 사업</SectionSubTitle>
      <Inner>
        <BusinessGrid>
          <BusinessItem>
            <div>
              <GiTalk size="2rem" />
            </div>
            <div>건축과 컨텐츠를 잇다.</div>유휴공간 리노베이션을 전문적으로 수행하기 위해 전문공사업 면허를 획득하였으며, 콘텐츠 확장을 위해 종합여행업 및 전문디자인회사 면허를 취득하였습니다. 공간기획부터 시공, 콘텐츠 운영까지 통합적인 서비스를 제공합니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <BsFillHouseFill size="2rem" />
            </div>
            <div>
              로컬에 숨결 불어넣기
              <a href="http://friendsunit.co.kr/">
                <BiWindowOpen size="100%" />
              </a> 
            </div>
            소멸위험지역 내 방치된 공간자원을 분석하고, 이를 활성화하기 위한 지자체 연구용역을 수행합니다. 자연과 지역 문화를 접목한 활용 방안을 모색하여, 지속가능한 로컬 경제 생태계를 조성합니다. 당진시, 보은군, 거창군 등에서 관련 용역을 수행하였습니다.  
          </BusinessItem>
          <BusinessItem>
            <div>
              <IoMdConstruct size="2rem" />
            </div>
            <div>'희망의 집수리' 사업</div>서울시 '희망의 집수리' 사업자로
            활동합니다. 저소득층/주거취약계층의 주거환경개선 공사를 진행합니다.
            2020년 27가구의 주거환경개선공사를 진행하였습니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <GiMeditation size="2rem" />
            </div>
            <div>《마인드트립 x 더몽》</div>
            명상과 함께 마음으로 향하는 여행 '마인드트립'과 청년들을 위한
            명상클래스 '명상하고 앉아있네' 를 진행하였습니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <GiThreeFriends size="2rem" />
            </div>
            <div>주거환경 시공</div>20년 이상 경과된 주택의 주거환경 개선
            공사를 진행합니다. 서울시 '가꿈주택' 사업의 사업자로 활동 중이며
            서대문구 노후주택 시공사례를 바탕으로 '가꿈주택'사업의 우수사례로
            선정되었습니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <GiHammerNails size="2rem" />
            </div>
            <div>아이가꿈 프로젝트 </div>기부금 크라우드펀딩을 기반으로 소외된 취약계층, 특히 미취학 아동을 둔 가구를 대상으로 주거 환경을 개선하는 프로젝트입니다. 민간 기부를 통해 열악한 주거 환경을 개선하여 보다 건강한 환경에서 성장할 수 있도록 지원합니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <BiCameraMovie size="2rem" />
            </div>
            <div>《팝업시네마X더몽: Private 한옥극장》</div>
            '모두를 위한 극장'과 팝업시네마를 진행하였습니다. 연인 혹은 친구들과
            함께, 특별한 우리만의 공간에서, 의미있는 시간을 보내고 싶은 분들을
            위해 준비했습니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <BiMapAlt size="2rem" />
            </div>
            <div>
              로컬비즈니스 인사이트 북 출간
              <a href="https://www.mingletown.co.kr">
                <BiWindowOpen size="100%" />
              </a>
            </div>
            로컬 상권이 형성되는 과정을 연구하고, 은평구의 개성 있는 로컬 상점들을 소개하는 서적을 발행하였습니다. 관찰자 시점에서 상권 변화의 주요 요소를 분석하며, 도시재생과 로컬 비즈니스의 가능성을 탐색합니다.
          </BusinessItem>
          <BusinessItem>
            <div>
              <AiFillFormatPainter size="2rem" />
            </div>
            <div>소셜벤처/사회적기업을 위한 공간조성 공사</div>
            한국농수산식품유통공사의 '청년키움식당', 성동구 지역자활센터 카페
            '다온', 성동구 사회적경제지원센터 '성수맘푸드' 등 여러
            소셜벤처/사회적기업의 공간 조성 공사를 진행하였습니다.
          </BusinessItem>
        </BusinessGrid>
      </Inner>
    </Section>
  );
};

export default About;
