import styled from "@emotion/styled";
import { darken } from "polished";
import { BiCheckCircle } from "react-icons/bi";
import Inner from "../layout/Inner";
import Section from "../layout/Section";
import mainImg1 from "../../resources/images/homepage/main1.jpg";
import communityImg from "../../resources/images/homepage/community.jpg";
import interiorImg from "../../resources/images/homepage/interior.jpg";
import shareHouseImg from "../../resources/images/homepage/share2.jpg";

const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const TitleSlogan = styled.div`
  font-size: 2rem;
  letter-spacing: 2px;
  line-height: 1.8;
  font-weight: bold;
  font-family: "IBM";
  color: ${({ theme }) => theme.colors.font.head};

  > span {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.main};
  }

  @media (max-width: 1200px) {
    font-size: calc(1rem + 1.5vw);
    margin-bottom: 2rem;
  }

  @media (max-width: 576px) {
    font-size: calc(0.3rem + 3vw);
    margin: 0 auto 2rem;
  }
`;

const TitleDesc = styled.div`
  font-size: 1rem;
  font-weight: 300;
  line-height: 2;
  font-family: "IBM";
  color: ${({ theme }) => theme.colors.font.light};

  @media (max-width: 576px) {
    font-size: 3vw;
    margin: 0 auto;
  }
`;

const ImgSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 600px;
  background: url("${mainImg1}");
  background-size: cover;
  color: ${({ theme }) => theme.colors.base};
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 2px;
  line-height: 2.5;
  font-weight: normal;
  font-family: "IBM";
  word-break: keep-all;

  @media (max-width: 576px) {
    font-size: 3vw;

    > div > div {
      width: 80%;
      margin: 0 auto;
    }
  }

  > div > div > span {
    font-size: 1.5rem;
    font-weight: bold;

    @media (max-width: 576px) {
      font-size: calc(0.3rem + 3vw);
    }
  }
`;

const Sticker = styled.div`
  position: absolute;
  border-left: 5px solid ${({ theme }) => darken(0.1, theme.colors.main)};
  background: ${({ theme }) => theme.colors.main};
  height: 12rem;
  width: 80%;
  bottom: -6rem;
  letter-spacing: 0;
  box-shadow: ${({ theme }) => theme.shadow.normal};
  right: 0;
  border-radius: 0.5rem 0 0 0.5rem;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  min-width: 1600px;

  @media (max-width: 1600px) {
    width: auto;
    max-width: 80%;
    min-width: 0;
    height: auto;
    padding: 1rem;
  }

  @media (max-width: 1010px) {
    display: none;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StickerSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  line-height: 1.8;
  font-size: 1rem;
  font-weight: normal;
  padding: 1rem;

  @media (max-width: 1010px) {
    margin: 0;
  }

  > h3 {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 0.5rem;

    > svg {
      margin-right: 0.5rem;
    }
  }

  @media (max-width: 1600px) {
    justify-content: flex-start;

    > h3 {
      align-items: flex-start;
    }

    > div {
    }
  }
`;

const MoreSection = styled.div`
  width: 100%;
  padding-top: 6rem;
  word-break: keep-all;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    @media (max-width: 576px) {
      padding: 2rem;
    }

    > div {
      margin-right: 2rem;
      min-width: 22rem;

      @media (max-width: 1200px) {
        margin-right: 0rem;
      }

      @media (max-width: 375px) {
        width: 100%;
        min-width: 0;
      }

      > h1 {
        align-self: flex-start;
        text-align: left;
        font-size: 2rem;
        margin-bottom: 4rem;
        line-height: 1.6;

        > div:first-of-type {
          text-align: left;
        }
        > div:last-of-type {
          text-align: right;
        }
      }
    }
  }
`;

const CountsWrap = styled.div`
  display: grid;
  width: 100%;
  margin-right: 2rem;
  grid-template-columns: 50% auto;
  color: ${({ theme }) => theme.colors.font.head};
  grid-gap: 1rem;

  @media (max-width: 1200px) {
    margin-bottom: 4rem;
  }

  @media (max-width: 1010px) {
    margin-bottom: 9rem;
  }
`;

const NumberCount = styled.div`
  line-height: 1.6;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
  margin-bottom: 3rem;

  div {
    font-size: 2.625rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.main};
  }
`;

const CardsVerticalWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 2rem;

  @media (max-width: 1200px) {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 2rem;
  }
`;

const CardsWrap = styled.div`
  margin-left: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  width: 20rem;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.base};
  word-break: keep-all;
  box-shadow: ${({ theme }) => theme.shadow.normal};

  > img {
    border-radius: 0.75rem 0.75rem 0 0;
    width: 100%;
    height: auto;
  }

  > div {
    width: 100%;
    height: auto;
    padding: 1.25rem;
    line-height: 1.8;

    > div {
      font-size: 0.875rem;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.main};
      margin-bottom: 1rem;
    }
  }

  & + & {
    margin-top: 2rem;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Home: React.FC = () => {
  return (
    <>
      <Section>
        <Inner>
          <TitleWrap>
            <TitleSlogan>
              버려지고 방치된 공간을
              <span> 다시 가치 있게 </span> <br />
              새로운 공간 문화를 창출하는 더몽입니다.
            </TitleSlogan>
            <TitleDesc>
              주식회사 더몽은
              <br />
              공간 레노베이션 기술을 바탕으로 벤처기업 인증
              <br />
              국토교통부 예비사회적기업을 거쳐, 창의/혁신형 사회적기업 인증
              <br />
              사회가치를 추구하는 소셜벤처기업입니다.
            </TitleDesc>
          </TitleWrap>
        </Inner>
      </Section>
      <ImgSection>
        <Inner>
          <div>
            <span>
              더몽은 노후주택과 빈집을 활용하여 새로운 공간문화를 창출하는
              회사입니다.
            </span>
            <div>
              <br />
              빈집을 업사이클링하여 지역사회를 재생
              <br />
              일상에 지친 이들에게 휴식을 제공해 삶을 재생
              <br />
              로컬 여행을 유치하여 침체된 지역 경제를 재생
            </div>
          </div>
        </Inner>
        <Sticker>
          <StickerSection>
            <h3>
              <BiCheckCircle size="1.8rem" />
              빈집/ 노후주택 공간환경 개선 공사 
            </h3>
            <div>
              • 오래되고 노후화된 공간 개선 공사를 진행합니다. 
              <br />
              • 취약계층의 주거환경 개선공사를 통해 슬럼화를 방지합니다.
              <br />• 방치된 공간에 새로운 콘텐츠를 채워 활력을 불어넣습니다.
            </div>
          </StickerSection>
          <StickerSection>
            <h3>
              <BiCheckCircle size="1.8rem" />
              청년 공유공간 운영 및 제공  
            </h3>
            <div>
              • 방치된 공간을 활용하여, 새로운 공유공간 문화를 선도합니다. 
              <br />
              • 재생된 공간에서 창업, 예술, 교육 문화 협업 프로그램을 진행합니다.
              <br />• 청년들이 자유롭게 소통하고 성장할 수 있는 열린 공간을 제공합니다.  
            </div>
          </StickerSection>
          <StickerSection>
            <h3>
              <BiCheckCircle size="1.8rem" />
              다시(AGAIN); 도시를 디자인 하다. 
            </h3>
            <div>
              • 소외된 도시에 숨결을 불어 넣습니다.
              <br />• 소멸위험지역의 활성화를 위한 연구용역을 수행합니다. 
              <br />• 도시재생을 통해 공동체 문화를 회복하고 사회적 가치를 창출합니다.
            </div>
          </StickerSection>
        </Sticker>
      </ImgSection>
      <Section color="white">
        <MoreSection>
          <Inner>
            <div>
              <h1>
                <div>'다시 되살아난 공간,</div> <div>우리리를 되살리는 시간'</div>
              </h1>
              <CountsWrap>
                <NumberCount>
                  <div>522+</div>커뮤니티 프로그램
                  <br /> 참가자 수
                </NumberCount>
                <NumberCount>
                  <div>256+</div>유휴공간 <br />
                  레노베이션 건 수
                </NumberCount>
                <NumberCount>
                  <div>86+</div>건텐츠 <br />
                  제작 건 수
                </NumberCount>
                <NumberCount>
                  <div>21+</div>빈집 / 유휴공간 <br />
                  컨설팅
                </NumberCount>
              </CountsWrap>
            </div>
            <CardsWrap>
              <CardsVerticalWrap>
                <Card>
                  <img src={interiorImg} alt="renovation"></img>
                  <div>
                    <div>공간 업싸이클링</div>노후건축물에 새로운
                    숨결을 불어넣는 리모델링 / 레노베이션 공사를 진행합니다.
                  </div>
                </Card>
                <Card>
                  <img src={communityImg} alt="livelounge"></img>
                  <div>
                    <div>컨텐츠 리브라운지</div>명상 클래스, 독립영화 상영제
                    등 여러 프로그램을 운영합니다.
                  </div>
                </Card>
              </CardsVerticalWrap>
              <div>
                <Card>
                  <img src={shareHouseImg} alt="themong_seoul"></img>
                  <div>
                    <div>더몽하우스 서울대점</div>입주민을 위한 더몽만의
                    차별화된 프렌즈 유닛 쉐어하우스
                  </div>
                </Card>
              </div>
            </CardsWrap>
          </Inner>
        </MoreSection>
      </Section>
    </>
  );
};

export default Home;
