import styled from "@emotion/styled";
import Inner from "../layout/Inner";
import Section, { SectionSubTitle, SectionTitle } from "../layout/Section";

const HistoryWrap = styled.div`
  margin: 0 auto;
`;

const HistoryYear = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.font.content};
  margin-bottom: 1rem;
`;

const HistoryContent = styled.div`
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.font.light};
  ::before {
    content: "• ";
  }
`;

const HistoryYearWrap = styled.div`
  margin-bottom: 3rem;

  @media (max-width: 576px) {
    padding: 0 2rem;
  }
`;

const History: React.FC = () => {
  return (
    <Section>
      <Inner>
        <HistoryWrap>
          <SectionTitle>HISTORY</SectionTitle>
          <SectionSubTitle>회사 연혁</SectionSubTitle>
          <HistoryYearWrap>
            <HistoryYear>2018</HistoryYear>
            <HistoryContent>
              2018.03 : 도시재생형 사회적기업가 육성과정 선정 (사회적기업진흥원)
            </HistoryContent>
            <HistoryContent>2018.05 : (주)더몽 법인 설립</HistoryContent>
            <HistoryContent>
              2018.11 : 예비사회적기업 지정 (국토교통부형 제2018-39호)
            </HistoryContent>
          </HistoryYearWrap>
          <HistoryYearWrap>
            <HistoryYear>2019</HistoryYear>
            <HistoryContent>
              2019.05 : 벤처기업 인증 (제 20190104515호 - 노후건물 리노베이션 특허
              등록)
            </HistoryContent>
          </HistoryYearWrap>
          <HistoryYearWrap>
            <HistoryYear>2020</HistoryYear>
            <HistoryContent>
              2020.09 : 중소벤처기업부 '생활혁신형 창업' 최우수상 수상
            </HistoryContent>
            <HistoryContent>
              2020.12 : 창의/혁신형 사회적기업 지정 (고용노동부 제 2020-387호)
            </HistoryContent>
          </HistoryYearWrap>
          <HistoryYearWrap>
            <HistoryYear>2021</HistoryYear>
            <HistoryContent>
              2021.10 : 소셜벤처 경연대회 - 전국대회 우수상 수상 
            </HistoryContent>
          </HistoryYearWrap>
          <HistoryYearWrap>
            <HistoryYear>2022</HistoryYear>
            <HistoryContent>
              2022.01 : 전문건설업 - 실내건축공사업 면허 획득
            </HistoryContent>
          </HistoryYearWrap>
          <HistoryYearWrap>
            <HistoryYear>2024</HistoryYear>
            <HistoryContent>
              2024.01 : 산업디자인 전문회사(환경, 시작디자인) 인증
            </HistoryContent>
            <HistoryContent>
              2024.07 : 로컬 비즈니스 인사이트 북 출간 : 은평구 로컬 상점 이야기
            </HistoryContent>
          </HistoryYearWrap>
        </HistoryWrap>
      </Inner>
    </Section>
  );
};

export default History;
