import styled from "@emotion/styled";

const StyledFooter = styled.footer`
  width: 100%;
  height: 20rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  bottom: 0;
  color: ${({ theme }) => theme.colors.base};
  background-color: ${({ theme }) => theme.colors.main};

  @media (max-width: 576px) {
    height: auto;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 2rem;
    padding-bottom: 4rem;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 576px) {
    & + & {
      margin-bottom: 2rem;
    }
  }
`;

const FooterColumnBottom = styled.div`
  height: 100%;
  padding-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 992px) {
    position: absolute;
    padding-bottom: 1rem;
    right: 1rem;
  }
`;

const FooterHeader = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const GridWrap = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.75rem;
`;

const FooterTitle = styled.div`
  font-size: 0.75rem;
  text-align: right;
`;

const FooterContent = styled.div`
  font-size: 0.75rem;
`;

const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <FooterColumnBottom>
        <FooterContent>
          Copyrightⓒ{new Date().getFullYear()} The Mong Inc. All rights
          reserved.
        </FooterContent>
      </FooterColumnBottom>
      <FooterColumn>
        <FooterHeader>COMPANY INFO</FooterHeader>
        <GridWrap>
          <FooterTitle>기업명</FooterTitle>
          <FooterContent>(주)더몽</FooterContent>
          <FooterTitle>대표</FooterTitle>
          <FooterContent>나윤도</FooterContent>
          <FooterTitle>위치</FooterTitle>
          <FooterContent>
            서울특별시 성동구 마장로39길 31, 5층
          </FooterContent>
          <FooterTitle>사업자등록번호</FooterTitle>
          <FooterContent>768-81-01064</FooterContent>
        </GridWrap>
      </FooterColumn>
      <FooterColumn>
        <FooterHeader>CONTACT</FooterHeader>
        <GridWrap>
          <FooterTitle>Mon - Fri</FooterTitle>
          <FooterContent>09:00 - 18:00</FooterContent>
          <FooterTitle>Sat - Sun</FooterTitle>
          <FooterContent>Closed</FooterContent>
          <FooterTitle>Tel.</FooterTitle>
          <FooterContent>02-498-2375</FooterContent>
          <FooterTitle>Email</FooterTitle>
          <FooterContent>yundo.na@themong.kr</FooterContent>
          <FooterTitle>KakaoTalk</FooterTitle>
          <FooterContent>eh263</FooterContent>
        </GridWrap>
      </FooterColumn>
    </StyledFooter>
  );
};

export default Footer;
